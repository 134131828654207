import React from "react"
import "../style/about.css"
import Layout from "../components/layout"

class Thankyou extends React.Component {  
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                <div className="container p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center text-aspirered" >Thank You</h1>
                            <hr className="thnk" />
                        </div>
                        <div className="col-md-12 text-center">
                            <br />
                            <p className="thnk-msg aspireblue">Thanks For Visiting Our Page <br /> We look forward to an extended relationship forever</p>
                            <span>
                                <hr />
                                <br />
                                <h4 className="aspireblue ptsans">Download Brochure</h4>
                                <hr className="thnk" />
                                <a href="https://www.sumadhuragroup.com/wp-content/uploads/2021/01/Aspire_Aurum_E_Brochure.pdf">
                                    <button className="btn-book">Download</button></a>
                            </span>
                        </div>
                    </div>
                </div>
                <script src="../Scripts/conversion.js"></script>
		<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
            </Layout>
        )
    }
}
export default Thankyou
